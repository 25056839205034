  <div class="lecture" *ngIf="card">
    <a (click)="goToVideo('link')">
        <div class="lecture-image">
            <div class="lecture-badges">
                <span class="sponsored" *ngIf="lecture.isSponsored()">{{'academy.Sponsored'|translate}}</span>
                <span class="free"
                      *ngIf="lecture.isFree() && !lecture.isWebinar() && (!user || (user && !user.premium_membership))">{{'academy.Free'|translate}}</span>
                <span class="free" *ngIf="lecture.isFree() && lecture.isWebinar() && (user && user.premium_membership)">{{'sponsor.Family_Webinar'|translate}}</span>
                <span class="free"
                      *ngIf="lecture.isFree() && lecture.isWebinar() && (!user || (user && !user.premium_membership))">{{'academy.Free_webinar'|translate}}</span>
                <span class="new" *ngIf="lecture.isNew() && !lecture.getMedia().isViewed(user)">{{'academy.New'|translate}}</span>
                <span class="sponsored" *ngIf="lecture.getMedia().isViewed(user)">{{'academy.Viewed'|translate}}</span>
            </div>
            <div class="lecture-fav" *ngIf="favoriteElement && !environment.mobile" [ngClass]="{'active': lecture.getFavorite(user)}">
                <i class="fa fa-heart" aria-hidden="true"
                   (click)="addMediaToFavorite();"></i>
            </div>
            <div class="lecture-play">
                <i class="far fa-play-circle"></i>
            </div>
            <div class="lecture-languages" *ngIf="lecture.getMedia().languages.length > 0">
                <img *ngFor="let language of lecture.getMedia().languages"
                     [src]="environment.cdn_base_url + '/assets-front/img/flags/' +  language + '.svg'"
                     class="flag" alt="">
            </div>
            <div class="lecture-duration">
                <span>{{lecture.getMedia().duration}}</span>
            </div>
            <img class="thumb_normal" [src]="lecture.getThumb('rectangular')" alt="">
            <img class="thumb_squared" *ngIf="!environment.mobile" [src]="lecture.getThumb('squared')" alt="">
            <div class="lecture-image-hover-content" *ngIf="!environment.mobile">
              <div class="lecture-image-hover-content-bg"></div>
              <span class="lecture-image-hover-content-view">View video</span>
            </div>
        </div>
        <div class="lecture-body">
            <div class="lecture-body-wrapper">
                <div class="lecture-body-author">
                    <div class="lecture-body-author-thumb" *ngIf="lecture.getAvatar()"
                         [ngStyle]="{'background-image': 'url(' + lecture.getAvatar() + ')', 'background-size':'cover'}"></div>
                    <p *ngIf="lecture.getSpeakersArr().length">{{'academy.by'|translate}} <a
                            (click)="goToUserProfile(speaker.getProfileUrl())"
                            *ngFor="let speaker of lecture.getSpeakersArr() | mapToIterable; index as i">{{(i > 0 ? ', '
                        : '') +
                        speaker.getFullName()}}</a></p>
                </div>
                <div class="clearfix"></div>

                <div class="lecture-comments" *ngIf="isForReport && lecture.comments_count > 0 || lecture.views > 100">
                  <span>{{lecture.comments_count === 1 ? '1 ' + ('pluralize.Comment'|translate) : lecture.comments_count > 1 ? lecture.comments_count + ' ' + ('pluralize.Comments'|translate) : ''}}{{lecture.views > 100 && lecture.comments_count > 0 ? ' - ' : ''}}{{lecture.views > 100 ? lecture.views + ' ' + ('pluralize.Views'|translate) : '' }}</span>
                </div>

                <h4 class="lecture-body-title"
                    [innerHTML]=" (translationService.getTranslatedTitle(lecture.getMediaTitles(), locale).length>56)? (translationService.getTranslatedTitle(lecture.getMediaTitles(), locale) | slice:0:56)+'...':(translationService.getTranslatedTitle(lecture.getMediaTitles(), locale)) "
                ></h4>
            </div>
            <div class="lecture-body-bottom">
                <div class="lecture-body-published">
                    <span>{{'webinar.Published_on'|translate}} {{lecture.getPublishedDate()}}</span>
                </div>
                <div class="lecture-body-sponsored" *ngIf="lecture.isSponsored() && !isForReport">
                    <p>{{'academy.Sponsored_by'|translate}} {{lecture.getSponsorName()}}</p>
                </div>
                <div class="lecture-body-comments">
                    <p *ngIf="lecture.getCommentsCount() > 0">{{lecture.getCommentsCount()}} {{
                        lecture.getCommentsCount() === 1 ?
                        ('pluralize.Comment'|translate) : ('pluralize.Comments'|translate) }}</p>
                </div>
                <div class="lecture-body-rating">
                    <ngb-rating [(rate)]="lecture.rating" max="5" [readonly]="true">
                        <ng-template let-fill="fill">
                            <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
                            <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
                            <i *ngIf="fill < 100 && fill > 0" class="fas fa-star-half-alt"></i>
                        </ng-template>
                    </ngb-rating>
                </div>
                <div class="lecture-body-tags" *ngIf="lecture.keywords && lecture.keywords.length > 0">
                    <span *ngFor="let tag of translationService.getTranslatedArray(lecture.keywords, locale)"> {{ tag['name']}} </span>
                </div>
            </div>

        </div>
    </a>
    <div *ngIf="favoriteElement && environment.mobile" class="favorite text-right mr-2" [ngClass]="{'active': lecture.getFavorite(user)}">
      <i class="fa fa-heart" aria-hidden="true"
         (click)="addMediaToFavorite();"></i>
    </div>
  </div>
<a (click)="goToVideo('link')">
    <div *ngIf="!card" class="row">

        <div class="col-4 col-md-5">
            <div class="lecture-image" (click)="goToVideo(lecture)">
                <div class="lecture-play">
                    <i class="fa"
                       [ngClass]="lecture.user_auth && lecture.user_auth[0] && lecture.user_auth[0].pivot.viewed===1 ? 'fa-check': 'fa-play-circle'"></i>
                </div>
                <div class="lecture-languages" *ngIf="lecture.media.languages.length > 0">
                    <img *ngFor="let language of lecture.media.languages"
                         [src]="environment.cdn_base_url + '/assets-front/img/flags/' +  language + '.svg'"
                         class="flag"
                         alt="">
                </div>
                <div class="lecture-duration">
                    <span>{{lecture.media && lecture.media.duration ? lecture.media.duration : ''}}</span>
                </div>
                <img [src]="lecture.getThumb('squared')">
                <div class="lecture-mask"></div>
            </div>
        </div>
        <div class="col-8 col-md-7">
            <div class="lecture-content">
                <h3 (click)="goToVideo(lecture)">{{lecture.media ?
                    translationService.getTranslatedAttribute('title',
                    lecture.media.translations, locale) : ''}}</h3>
                <div class="author media">
                    <div class="media-body" *ngIf="lecture.media && lecture.media.speakers.length === 1">
                        <h6>{{lecture.media.speakers[0].title + ' ' + lecture.media.speakers[0].first_name + ' ' +
                            lecture.media.speakers[0].last_name}}</h6>
                        <span>
                                        {{(lecture.media.speakers[0].specialty ? translationService.getTranslatedName(lecture.media.speakers[0].specialty.translations, locale) : '') +
                                        (lecture.media.speakers[0].specialty && lecture.media.speakers[0].country ? ', ' : '') +
                                        (lecture.media.speakers[0].country ? translationService.getTranslatedName(lecture.media.speakers[0].country.translations, locale) : '')}}
                                    </span>
                    </div>
                </div>
                <div class="lecture-published">
                    <p>{{'webinar.Published_on'|translate}}
                       {{dateMomentService.getPublicationDate(lecture.publication_date)}}
                        {{academyMedia?.academy_course?.captation_source
                        ? ' - ' + ('academy.Recorded_during'|translate) + ' ' +
                        academyMedia.getCaptationSource() : ''}}</p>
                </div>
                <div class="lecture-comments" *ngIf="lecture.comments_count > 0 || lecture.views > 100">
                    <span>{{lecture.comments_count === 1 ? '1 ' + ('pluralize.Comment'|translate) : lecture.comments_count > 1 ? lecture.comments_count + ' ' + ('pluralize.Comments'|translate) : ''}}{{lecture.views > 100 && lecture.comments_count > 0 ? ' - ' : ''}}{{lecture.views > 100 ? lecture.views + ' ' + ('pluralize.Views'|translate) : '' }}</span>
                </div>
                <div class="lecture-rating">
                    <ngb-rating [rate]="lecture.rating" max="5" [readonly]="true">
                        <ng-template let-fill="fill">
                            <i *ngIf="fill === 100" class="fa fa-star-media fa-star"></i>
                            <i *ngIf="fill === 0" class="far fa-star-media fa-star"></i>
                            <i *ngIf="fill < 100 && fill > 0" class="fas fa-star-half-alt"></i>
                        </ng-template>
                    </ngb-rating>
                </div>
                <div class="lecture-sponsored" *ngIf="lecture.sponsorOrGrant && lecture.sponsorOrGrant.id">
                    <p>
                        {{lecture.granted? ('academy.Granted_by'|translate) :
                        ('academy.Sponsored_by'|translate)}}
                        <span>
									{{lecture.sponsorOrGrant.company_name}}
									</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</a>
<!-- Lecture sur deux col à l'inverse de la version card -->
