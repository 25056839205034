<div class="page">
    <app-section-top [title]="'academy.Alert'" [subtitle]="'alert.Main_subtitle'|translate"
                     [backgroundUrl]="environment.cdn_base_url + '/assets-front/img/cover-alert.jpg'"></app-section-top>
  <app-loader *ngIf="loading"></app-loader>
    <ng-container *ngIf="!loading">
      <div [className]="!environment.mobile ? 'content alert-form' : 'content alert-form has-back-button-padding-top-15'" style="margin-top:15px;" *ngIf="group$ | async as group">
        <a *ngIf="environment.mobile" (click)="historyService.goBack()" class="return-button button button-blue" style="margin-left: 10px; color: white;">
          <i class="fa fa-chevron-left" style="margin-right: 5px"></i>{{'academy.Return'|translate}}
        </a>
        <div class="breadcrumbs">
          <ul>
            <li>
              <a [routerLink]="['/alert']">{{'premium.IMCAS_Alert'|translate}}</a>
            </li>
            <li>
              <a [routerLink]="['/','alert', slug]">{{ translationService.getTranslatedName(group.translations,
                locale) }}</a>
            </li>
            <li *ngIf="updateRoute">
              <a [routerLink]="['/alert/'+slug+'/'+newCase.id+'/'+newCase.slug]">{{newCase.title}}</a>
            </li>
            <li>
              <span>{{ updateRoute ? 'Update' : 'company_profile.Create'|translate}}</span>
            </li>
          </ul>
        </div>
        <div *ngIf="!submittedCase" class="alert-form-instruction">
          <p *ngIf="!updateRoute" [innerHTML]="'alert.Submit_case_intro'|translate"></p>
        </div>
        <form *ngIf="!submittedCase" class="form" #caseForm="ngForm" (ngSubmit)="submitCase(newCase)">
          <div class="form-title">
            <h4>{{translationService.getTranslatedAttribute('name', group.translations, locale)}}</h4>
          </div>
          <div class="row">
            <div class="form-radio col-md-12">
              <label class="form-header">{{ 'alert.Type_case'|translate }}</label>
              <div class="custom-control custom-radio">
                <input type="radio" name="type" id="type-complication" class="custom-control-input"
                       [(ngModel)]="newCase.type" value="complication">
                <label class="custom-control-label"
                       for="type-complication">{{ 'alert.Complication'|translate }} <i
                  class="fa fa-info-circle"
                  [ngbTooltip]="'alert.Complication_tooltip'|translate"></i></label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" name="type" id="type-inquiry" class="custom-control-input"
                       [(ngModel)]="newCase.type" value="inquiry">
                <label class="custom-control-label" for="type-inquiry">{{ 'alert.Inquiry'|translate }} <i
                  class="fa fa-info-circle" [ngbTooltip]="'alert.Inquiry_tooltip'|translate"></i></label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" name="type" id="type-discussion" class="custom-control-input"
                       [(ngModel)]="newCase.type" value="discussion">
                <label class="custom-control-label" for="type-discussion">{{ 'alert.Discussion'|translate }}
                  <i class="fa fa-info-circle"
                     [ngbTooltip]="'alert.Discussion_tooltip'|translate"></i> </label>

              </div>

              <div class="custom-control custom-radio">
                <input type="radio" name="type" id="type-report" class="custom-control-input"
                       [(ngModel)]="newCase.type" value="report">
                <label class="custom-control-label" for="type-report">{{ 'alert.Report'|translate }} <i
                  class="fa fa-info-circle" style="    "
                  [ngbTooltip]="'alert.Report_tooltip'|translate"></i></label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-{{ environment.domain != 'aesthetics' ? 3 : 6 }}">
              <label class="form-header" for="">{{'evaluation.Select_a_theme'|translate}}</label>
              <select
                id="theme"
                name="theme"
                class="form-control"
                [ngModel]="newCase.theme_id"
                (ngModelChange)="themeChange($event)">
                <option [ngValue]="undefined">{{'evaluation.Select_a_theme'|translate}}</option>
                <option *ngFor="let option of group.themes"
                        [ngValue]="option.id">
                  {{translationService.getTranslatedName(option.translations, locale)}}
              </select>
            </div>
            <div class="form-group col-md-3" *ngIf="environment.domain != 'aesthetics'">
              <label class="form-header" for="">{{'alert.Secondary_theme'|translate}}</label>
              <select
                id="theme2"
                name="theme2"
                class="form-control"
                [ngModel]="newCase.theme2_id"
                (ngModelChange)="newCase.theme2_id = $event">
                <option [ngValue]="undefined">{{'evaluation.Select_a_theme'|translate}}</option>
                <option *ngFor="let option of group.themes"
                        [ngValue]="option.id">
                  {{translationService.getTranslatedName(option.translations, locale)}}
              </select>
            </div>
            <div class="form-group col-md-6">
              <label class="form-header">{{'alert.Select_language'|translate}}</label>
              <select
                id="locale"
                name="locale"
                class="form-control"
                [ngModel]="newCase.locale"
                (ngModelChange)="newCase.locale = $event">
                <option [ngValue]="undefined">{{'alert.Select_language'|translate}}</option>
                <option *ngFor="let option of locales | mapToIterable"
                        [ngValue]="option.locale">
                  {{option.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-checkbox" *ngIf="threatmentsByTheme(newCase.theme_id).length > 0 && newCase.theme_id && groupObj.with_treatment">
            <label class="form-header">{{ 'alert.Select_treatments'|translate }}</label>
            <div class="checkbox-element" *ngFor="let treatment of group.treatments | mapToIterable; index as i">
              <div class="custom-control custom-checkbox" *ngIf="newCase.theme_id == treatment.theme_id">
                <input *ngIf="hasTreatment(treatment.id)"
                       type="checkbox"
                       checked
                       [id]="'treatments-'+ i"
                       (change)="treatmentChange(treatment, $event.target.checked)"
                       class="custom-control-input">
                <input *ngIf="!hasTreatment(treatment.id)"
                       type="checkbox"
                       [id]="'treatments-'+ i"
                       (change)="treatmentChange(treatment, $event.target.checked)"
                       class="custom-control-input">
                <label class="custom-control-label" [for]="'treatments-'+ i">{{translationService.getTranslatedName(treatment.translations,
                  locale)}}</label>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="purposeData.length > 0">
            <label class="form-header">{{ 'alert.Purpose'|translate }}</label>
            <ng-select [items]="purposeData"
                       name="purpose"
                       [multiple]="true"
                       bindLabel="text"
                       bindValue="id"
                       [closeOnSelect]="false"
                       [hideSelected]="true"
                       [(ngModel)]="purposeValue"
                       (ngModelChange)="purposeChanged($event)">
            </ng-select>
          </div>
          <div class="form-group"
               *ngIf="complicationsData && newCase && complicationsData.length > 0 && newCase.type == 'complication'">
            <label class="form-header">{{ 'alert.Select_complications'|translate }}</label>
            <ng-select [items]="complicationsData"
                       name="complication"
                       [multiple]="true"
                       [closeOnSelect]="false"
                       [hideSelected]="true"
                       bindLabel="text"
                       bindValue="id"
                       [(ngModel)]="complicationsValue"
                       (ngModelChange)="complicationsChanged($event)">
            </ng-select>
          </div>
          <div class="form-group">
            <label class="form-header" for="">
              <span *ngIf="newCase.type == 'complication' || newCase.type == 'report'">{{ 'alert.Case_title'|translate }}</span>
              <span *ngIf="newCase.type == 'inquiry' || newCase.type == 'discussion' || !newCase.type">{{ 'account.Title'|translate }}</span>
              <small style="text-transform: none">({{ 'abstract.Form_xxx_characters_maximum'|translate: {limit:80} }})</small>
            </label>
            <input type="text" class="form-control" name="title" placeholder="(80 characters max)"
                   [(ngModel)]="newCase.title" maxlength="80">
          </div>
          <div class="form-group" *ngIf="newCase.type != 'discussion'">
            <label class="form-header" for="">{{ 'alert.Create_patient_descr'|translate }}</label>
            <ejs-richtexteditor [(ngModel)]="newCase.patient_description" [toolbarSettings]="tools" name="patient_description"></ejs-richtexteditor>
          </div>
          <div class="form-group">
            <label class="form-header" *ngIf="newCase.type != 'discussion'">
              <span>{{ 'alert.Create_case_descr'|translate }}</span>
              <small style="text-transform: none">{{ 'alert.Create_case_descr2'|translate }}</small>
            </label>
            <label class="form-header" *ngIf="newCase.type == 'discussion'">{{ 'alert.Topic_descr'|translate }}</label>
            <ejs-richtexteditor [(ngModel)]="newCase.case_description" [toolbarSettings]="tools" name="case_description"></ejs-richtexteditor>
          </div>
          <p class="text-secondary" style="font-size: 13px; font-weight: bold;">{{'alert.accepted_format' | translate}} - {{'alert.max_medias' | translate}}</p>
          <div class="form-group">
            <p>{{ 'alert.add_files'|translate }}</p>
            <span *ngIf="newCase.type == 'complication' || newCase.type == 'inquiry' || newCase.type == 'report'">{{ 'alert.Picture_recommended'|translate }}</span>
            <span style="color: #d00;font-weight: bold;">{{ 'alert.common_please_upload'|translate }}</span>
            <span *ngIf="filesError === 'Error'" class="badge badge-danger">
                        {{ ('alert.Type_of_file_error'|translate) + ' : .' + extension + ' Not supported'}}
                  </span>
            <span *ngIf="filesError === 'Max'" class="badge badge-danger">
                         {{'alert.max_files_reached' | translate}} - {{'alert.max_medias' | translate}}
                  </span>
            <span *ngIf="filesError === 'Size'" class="badge badge-danger">
                       {{fileName}} {{'alert.max_files_reached' | translate}} - {{'alert.max_files_size' | translate}}
                  </span>
            <span *ngIf="filesError === 'Back'" class="badge badge-danger my-1">
                    {{'alert.Something_went_wrong' | translate}}
                </span>
            <span *ngIf="filesError === 'Max2'" class="badge badge-danger">
                         {{'alert.max_files_reached' | translate}} - {{'alert.max_medias' | translate}} ({{'alert.delete_for_space' | translate}})
            </span>
            <span *ngIf="uploaded" class="badge badge-success">
                      <i class="fa fa-check"></i>
              {{ 'academy.File_uploaded'|translate }}
              </span>
            <div class="form-single" *ngFor="let file of newCaseFiles; index as i">

              <img  class="img-responsive" style="{{environment.mobile ? 'max-width: 200px' : 'max-width: 400px'}}"  src="{{file.location}}" *ngIf="file.location && isPicture(file)">
              <a  *ngIf="file.location && !isPicture(file) && !isVideo(file.title)" [href]="file.location" target="_blank"><i class="fas fa-file"></i> {{file.title}}</a>
              <video  class="img-responsive" style="{{environment.mobile ? 'max-width: 200px' : 'max-width: 400px'}}" *ngIf="file.location && isVideo(file.title)" controlsList="nodownload" controls >
                <source [src]="file.location">
              </video>
              <input *ngIf="!file.location" type="text" class="form-control" [name]="'file'+ i +'Title'"
                     disabled placeholder="(80 characters max)" [(ngModel)]="file.text">
              <label class="custom-file">
                <input type="file" class="custom-file-input" [name]="'file'+ i" [accept]="acceptedFormat"
                       (change)="onFileChange(i, $event)" [multiple]="!file.location">
                <span class="button button-violet-outline custom-file-button">{{ 'company_account.Upload_your_file'|translate }}</span>
              </label>
              <span (click)="removeFile(i)" class="remove">- {{ 'alert.remove_file'|translate }}</span>
            </div>
            <div class="form-add" *ngIf="newCaseFiles.length < 5">
              <label>
                <input (change)="addFiles($event)" type="file" [accept]="acceptedFormat"
                       class="custom-file-input" style="display: none;" multiple>
                <a >+ {{ 'alert.add_another_file'| translate }}</a>
              </label>
            </div>
          </div>
          <input type="hidden" [ngModel]="newCase.alert_group_id" name="alert_group_id">
          <div class="form-group">
            <div class="custom-control custom-checkbox">

              <input type="checkbox" id="accept-group" name="accept-group" [checked]="acceptRules"
                     (change)="acceptRules = !acceptRules"
                     class="custom-control-input">
              <label class="custom-control-label" for="accept-group">
                {{ 'alert.I_accept'|translate }} <a [routerLink]="'/terms-of-use/alert'" target="_blank">{{ 'alert.Alert_rules'|translate }}</a>
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" id="accept-conditions" name="accept-conditions" [(ngModel)]="userAcceptConditions"
                     class="custom-control-input">
              <label class="custom-control-label" for="accept-conditions"
                     [innerHTML]="'alert.I_accept_conditions'|translate"></label>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" id="anonymous-choice" name="accept-group" [(ngModel)]="newCase.anonymous"
                     class="custom-control-input">
              <label class="custom-control-label" for="anonymous-choice"
                     [innerHTML]="'alert.Prefer_stay_anon'|translate"></label>
            </div>
          </div>
          <div *ngIf="errors" [innerHTML]="errors" class="alert alert-danger"></div>
          <div class="form-submit">
            <button class="button button-orange" type="submit"  *ngIf="!submitInProcess" >{{ updateRoute ? 'Save and submit again' : 'alert.Submit_case'|translate }}</button>
            <a class="button button-disabled"  *ngIf="submitInProcess" href="javascript:void(0)"><i class="fa fa-spinner fa-spin"></i> {{ 'alert.Loading_case'|translate }}</a>
          </div>
        </form>
      </div>
    </ng-container>
    <div class="content alert-created text-center" *ngIf="submittedCase">
        <span><i class="fa fa-check-circle"></i></span>
        <p *ngIf="!updateRoute">{{ 'alert.Case_published'|translate }}</p>
        <p *ngIf="updateRoute">{{ 'alert.Case_updated'|translate }}</p>
        <p [routerLink]="['/alert', slug, !updateRoute ? submittedCase.id : newCase.id, !updateRoute ? submittedCase.slug : slug]"><span class="access-case">{{ 'alert.Access_case'|translate }}</span></p>
    </div>

</div>
