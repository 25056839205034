import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AcademyMedia} from '../../models/academy-media.model';
import {TranslationService} from '../../services/translation.service';
import {environment} from '../../../environments/environment';
import {MediaService} from '../../services/media.service';
import {User} from '../../models/user.model';
import {Router} from '@angular/router';
import {LocaleService} from '../../services/locale.service';
import {DateMomentService} from '../../services/date-moment.service';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-lecture',
    templateUrl: './lecture.component.html',
    styleUrls: ['./lecture.component.scss'],
    providers: [MediaService]
})
export class LectureComponent implements OnInit {
    @Input() lecture: AcademyMedia;
    environment: any = environment;
    @Input() user: User;
    @Input() card: boolean;
    @Input() thumbFormat: string;
    @Input() favoriteElement: boolean = true;
    @Input() isForReport: boolean = false;
    locale: string;

    constructor(public translationService: TranslationService,
                private mediaService: MediaService,
                public dateMomentService: DateMomentService,
                private router: Router,
                private localeService: LocaleService) {
    }

    ngOnInit() {
        this.locale = this.localeService.currentLocale();
    }

    goToUserProfile(url) {
        this.router.navigateByUrl(url);
    }

    goToVideo(type?: string) {
      if (type && type === 'link') {
        this.router.navigateByUrl('/show/' + this.lecture.getId() + '/' + this.lecture.getSlug());
      } else {
        this.mediaService.goToVideo(this.lecture);
      }
    }

    addMediaToFavorite() {
        this.mediaService.addMediaToFavorite(this.lecture.getId()).subscribe(
            () => {
                const favorite = this.lecture.getFavorite(this.user);
                if (favorite === true) {
                    this.lecture.getMedia().favorite = false;
                    this.user.removeFavoriteAcademyMedia(this.lecture.getId());
                } else {
                    this.lecture.getMedia().favorite = true;
                    if (typeof this.user.addFavoriteAcademyMedia === 'function') {
                        this.user.addFavoriteAcademyMedia(this.lecture.getId());
                    }
                }
            },
            err => {}
        );
    }
}
